<template>
  <!-- <img src="../../../assets/logo.png" style="max-height: 80px" /> -->

  <div class="invoice-box" v-if="load">
    <table cellpadding="0" cellspacing="0">
      <tr class="top">
        <td colspan="2">
          <table>
            <tr>
              <td class="title">
                <img
                  src="../../../assets/logo2.png"
                  style="width: 100%; max-width: 230px"
                />
              </td>

              <td></td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="information">
        <td colspan="2">
          <table>
            <tr>
              <td>
                Farah Awni Selman<br />

                Messestraße 1, 6850 Dornbirn<br />
                T +43 (0) 5572 3888 562<br />
                H +43 (0) 660 10 2 10 10
              </td>

              <td>
                <!-- {{ body.fname }} {{ body.lname }}<br /> -->
                Rechnungsnummer #: {{ body.id }}<br />
                Buchungsdatum: {{ $durationFormat(body.createdAt) }}<br />
                Termin Datum: {{ $durationFormat(body.start) }} <br />

                Zahlungsart:
                <span v-if="body.paymentMethod == 'online'"
                  >Externe Gast - Online</span
                >
                <span v-if="body.paymentMethod == 'cash'"
                  >Externe Gast - Vor Ort</span
                >
                <span v-if="body.paymentMethod == 'hotal'">Hotel Gäste</span>
                <span v-if="body.paymentMethod == 'Gift'"
                  >Gutschein einlösen</span
                >
                <span v-if="body.paymentMethod == 'Package'"
                  >10er Block einlösen</span
                >
                <!-- <br/>
                {{ body.baytype == 'Cash2' ? '#' + body.hotel : 'EX' }} -->
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <!-- <tr class="heading">
        <td>Payment Method</td>

        <td>Check #</td>
      </tr> -->

      <tr class="details">
        <td>{{ body.fname }} {{ body.lname }}</td>

        <!-- <td>{{ body.baytype == 'Cash2' ? '#' + body.hotel : 'EX' }}</td> -->
      </tr>

      <tr class="heading">
        <td>Artikel</td>

        <td>Preis</td>
      </tr>

      <tr class="item" v-if="showM">
        <td v-if="body.massageId">
          <span
            style="color: red"
            @click="
              showM = false;
              setNewValue();
            "
          >
            X
          </span>
          {{ JSON.parse(body.pricesId.name).de }} -
          {{ body.adminsId.fullName }} - (
          {{ $durationFormatFull2(body.start, body.end) }}
          )
        </td>
        <td v-if="body.pricesId">
          {{
            body.pricesId.minutes + " Minutes = " + body.pricesId.price + "€"
          }}
        </td>
      </tr>

      <tr class="item" v-for="(x, index) in list" :key="index">
        <td>
          <span
            style="color: red"
            @click="
              list.splice(index, 1);
              setNewValue();
            "
          >
            X
          </span>
          {{ JSON.parse(x.pricesId.name).de }} - {{ x.adminsId.fullName }} - (
          {{ $durationFormatFull2(x.start, x.end) }}
          )
        </td>
        <td>
          {{ x.pricesId.minutes + " Minutes = " + x.pricesId.price + "€" }}
        </td>
      </tr>

      <tr class="item" v-for="(x, index) in list2" :key="index">
        <td>
          <span
            style="color: red"
            @click="
              list2.splice(index, 1);
              setNewValue();
            "
          >
            X
          </span>
          {{ JSON.parse(x.pricesId.name).de }} - {{ x.adminsId.fullName }} - (
          {{ $durationFormatFull2(x.start, x.end) }}
          )
        </td>
        <td>
          {{ x.pricesId.minutes + " Minutes = " + x.pricesId.price + "€" }}
        </td>
      </tr>

      <tr class="item" v-for="(x, index) in body.itemsList" :key="index">
        <td>
          <span
            style="color: red"
            @click="
              body.itemsList.splice(index, 1);
              setNewValue();
            "
          >
            X
          </span>
          {{ x.productsId.pname }}
        </td>
        <td>{{ Number(x.price * x.quantity) + "€" }}</td>
      </tr>

      <tr>
        <td>
          <br />
          <br />
          <br />
          <br />
        </td>

        <td>
          <br />
          <br />
          <br />
          <br />
        </td>
      </tr>

      <tr class="item">
        <td style="border-bottom: 2px solid #000"></td>
        <td style="border-bottom: 2px solid #000"></td>
      </tr>

      <tr class="total">
        <td></td>

        <td v-if="newval">
          Gesamtbetrag inkl MWSt.:
          {{
            Number(
              body.itemsList
                .map((el) => el.price)
                .reduce((a, b) => Number(a) + Number(b), 0)
            ) +
            Number(
              list
                .map((el) => el.pricesId.price)
                .reduce((a, b) => Number(a) + Number(b), 0)
            ) +
            Number(
              list2
                .map((el) => el.pricesId.price)
                .reduce((a, b) => Number(a) + Number(b), 0)
            ) +
            (showM ? Number(body.pricesId.price) : 0)
          }}€ <br /><br /><br /><br /><br />

          Tip:<span>........................</span>
          <br /><br /><br /><br /><br />
        </td>
      </tr>
      <tr class="total">
        <td>
          <span v-if="body.paymentMethod == 'online'">Online</span>
          <span v-if="body.paymentMethod == 'cash'">Vor Ort</span>
          <span v-if="body.paymentMethod == 'hotel'"
            >Zimmer Nr : {{ body.hotel }}</span
          >
          <span v-if="body.paymentMethod == 'Gift'"
            >Gutschein Nr :{{ body.giftsId ? body.giftsId.id : "" }}</span
          >
          <span v-if="body.paymentMethod == 'Package'"
            >10er Block Nr :{{
              body.packagesUsersId ? body.packagesUsersId.id : ""
            }}</span
          >
        </td>

        <td style="border-top: none">
          Unterschrift:<span
            >................................................</span
          >
        </td>
      </tr>

      <tr>
        <td><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></td>

        <td><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></td>
      </tr>
      <tr>
        <td>
          Bankverbindung:
          <br />
          Farah Awni Selman
          <br />
          IBAN: AT17 3742 2000 0014 0574
          <br />
          BIC:RVVGAT2B422
        </td>

        <td>
          Email: info@kleopatramassage-beauty.com
          <br />
          Webseite: https://kleopatramassage-beauty.com
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const user = JSON.parse(localStorage.adminKB);

    return {
      showM: true,
      user,
      load: false,
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        id: null,
        sexType: null,
        fname: null,
        lname: null,
        phone: null,
        email: null,
        paymentMethod: null,
        paymentDone: null,
        address: null,
        zip: null,
        hotel: null,
        city: null,
        country: null,
        start: null,
        end: null,
        Preise: null,
        HotelPreise: null,
        HotelZN: null,
        Bar: null,
        karteVisa: null,
        kartekarte: null,
        Gutshein: null,
        kurs: null,
        online: null,
        note: null,
        paymentCCID: null,
        paymentCCdone: false,
        paymentCCdoneDescription: null,

        massageId: {
          id: null,
          name: null,
          image: null,
        },
        pricesId: {
          id: null,
          name: { en: null, de: null },
          minutes: null,
          price: null,
        },
        giftsId: null,
        packagesUsersId: null,
        adminsId: {
          id: null,
          fullName: null,
        },
        itemsList: [
          {
            id: null,
            date: null,
            quantity: null,
            price: null,

            productsId: {
              id: null,
              code: null,
              pname: null,
              image: null,
            },
          },
        ],
      },
      productsAdd: {
        storeItemsId: null,
        quantity: 1,
        // price: 1,
      },
      id: null,
      isInvalid: false,
      disabledAdd: false,
      displayProductsAdd: false,
      storeItemsList: [],
      warehouseList: [],
      taxesList: [],
      productsList: [],

      list: [],
      list2: [],
      newval: true,
    };
  },
  methods: {
    setNewValue() {
      this.newval = false;
      this.newval = true;
    },
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`appointments/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
          this.load = true;

          this.$http
            .get(`appointments?appointmentsId=${this.id}`)
            .then((res) => {
              this.list = res.data.data;
            });

          let url = `appointments?usersId=${this.body.usersId.id}&sexType=${this.body.sexType}&fname=${this.body.fname}&lname=${this.body.lname}`;
          url += `&paymentMethod=${this.body.paymentMethod}`;
          url += `&createdAtStart=${this.$durationFormatCraetedAt(
            this.body.createdAt,
            "s"
          )}`;
          url += `&createdAtEnd=${this.$durationFormatCraetedAt(
            this.body.createdAt,
            "e"
          )}`;

          if (this.body.country) {
            url += `&country=${this.body.country}`;
          }

          if (this.body.city) {
            url += `&city=${this.body.city}`;
          }

          if (this.body.zip) {
            url += `&zip=${this.body.zip}`;
          }

          if (this.body.address) {
            url += `&address=${this.body.address}`;
          }

          if (this.body.hotel) {
            url += `&hotel=${this.body.hotel}`;
          }

          if (this.body.email) {
            url += `&email=${this.body.email}`;
          }

          if (this.body.giftsId) {
            url += `&giftsId=${this.body.giftsId.id}`;
          }

          if (this.body.packagesUsersId) {
            url += `&packagesUsersId=${this.body.packagesUsersId.id}`;
          }

          this.$http.get(url).then((res) => {
            let list_x = res.data.data;

            for (const item of list_x) {
              console.log(item.id, this.id);
              console.log(Number(item.id) != Number(this.id));

              if (Number(item.id) != Number(this.id)) {
                this.list2.push(item);
              }
            }

            if (this.body.uuid) {
              let url2 = `appointments?uuid=${this.body.uuid}`;
              this.$http.get(url2).then((res2) => {
                const list2 = res2.data.data;
                for (const item of list2) {
                  console.log(
                    item.id,
                    this.id,
                    this.list2.findIndex((el) => el.id == item.id) == -1
                  );
                  console.log(Number(item.id) != Number(this.id));
                  console.log(
                    this.list2.findIndex((el) => el.id == item.id) == -1
                  );
                  console.log(
                    this.list2.findIndex((el) => el.id == item.id) == -1 &&
                      Number(item.id) != Number(this.id)
                  );

                  if (
                    this.list2.findIndex((el) => el.id == item.id) == -1 &&
                    Number(item.id) != Number(this.id)
                  ) {
                    this.list2.push(item);
                  }
                }
              });
            }
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
  },
};
</script>

<style scoped>
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
  background-color: #fff;
}

@media print {
  .invoice-box {
    max-width: unset;
    box-shadow: none;
    border: 0px;
  }
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
</style>
