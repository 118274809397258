var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.load)?_c('div',{staticClass:"invoice-box"},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_vm._m(0),_c('tr',{staticClass:"information"},[_c('td',{attrs:{"colspan":"2"}},[_c('table',[_c('tr',[_vm._m(1),_c('td',[_vm._v(" Rechnungsnummer #: "+_vm._s(_vm.body.id)),_c('br'),_vm._v(" Buchungsdatum: "+_vm._s(_vm.$durationFormat(_vm.body.createdAt))),_c('br'),_vm._v(" Termin Datum: "+_vm._s(_vm.$durationFormat(_vm.body.start))+" "),_c('br'),_vm._v(" Zahlungsart: "),(_vm.body.paymentMethod == 'online')?_c('span',[_vm._v("Externe Gast - Online")]):_vm._e(),(_vm.body.paymentMethod == 'cash')?_c('span',[_vm._v("Externe Gast - Vor Ort")]):_vm._e(),(_vm.body.paymentMethod == 'hotal')?_c('span',[_vm._v("Hotel Gäste")]):_vm._e(),(_vm.body.paymentMethod == 'Gift')?_c('span',[_vm._v("Gutschein einlösen")]):_vm._e(),(_vm.body.paymentMethod == 'Package')?_c('span',[_vm._v("10er Block einlösen")]):_vm._e()])])])])]),_c('tr',{staticClass:"details"},[_c('td',[_vm._v(_vm._s(_vm.body.fname)+" "+_vm._s(_vm.body.lname))])]),_vm._m(2),(_vm.showM)?_c('tr',{staticClass:"item"},[(_vm.body.massageId)?_c('td',[_c('span',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.showM = false;
            _vm.setNewValue();}}},[_vm._v(" X ")]),_vm._v(" "+_vm._s(JSON.parse(_vm.body.pricesId.name).de)+" - "+_vm._s(_vm.body.adminsId.fullName)+" - ( "+_vm._s(_vm.$durationFormatFull2(_vm.body.start, _vm.body.end))+" ) ")]):_vm._e(),(_vm.body.pricesId)?_c('td',[_vm._v(" "+_vm._s(_vm.body.pricesId.minutes + " Minutes = " + _vm.body.pricesId.price + "€")+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.list),function(x,index){return _c('tr',{key:index,staticClass:"item"},[_c('td',[_c('span',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.list.splice(index, 1);
            _vm.setNewValue();}}},[_vm._v(" X ")]),_vm._v(" "+_vm._s(JSON.parse(x.pricesId.name).de)+" - "+_vm._s(x.adminsId.fullName)+" - ( "+_vm._s(_vm.$durationFormatFull2(x.start, x.end))+" ) ")]),_c('td',[_vm._v(" "+_vm._s(x.pricesId.minutes + " Minutes = " + x.pricesId.price + "€")+" ")])])}),_vm._l((_vm.list2),function(x,index){return _c('tr',{key:index,staticClass:"item"},[_c('td',[_c('span',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.list2.splice(index, 1);
            _vm.setNewValue();}}},[_vm._v(" X ")]),_vm._v(" "+_vm._s(JSON.parse(x.pricesId.name).de)+" - "+_vm._s(x.adminsId.fullName)+" - ( "+_vm._s(_vm.$durationFormatFull2(x.start, x.end))+" ) ")]),_c('td',[_vm._v(" "+_vm._s(x.pricesId.minutes + " Minutes = " + x.pricesId.price + "€")+" ")])])}),_vm._l((_vm.body.itemsList),function(x,index){return _c('tr',{key:index,staticClass:"item"},[_c('td',[_c('span',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.body.itemsList.splice(index, 1);
            _vm.setNewValue();}}},[_vm._v(" X ")]),_vm._v(" "+_vm._s(x.productsId.pname)+" ")]),_c('td',[_vm._v(_vm._s(Number(x.price * x.quantity) + "€"))])])}),_vm._m(3),_vm._m(4),_c('tr',{staticClass:"total"},[_c('td'),(_vm.newval)?_c('td',[_vm._v(" Gesamtbetrag inkl MWSt.: "+_vm._s(Number( _vm.body.itemsList .map((el) => el.price) .reduce((a, b) => Number(a) + Number(b), 0) ) + Number( _vm.list .map((el) => el.pricesId.price) .reduce((a, b) => Number(a) + Number(b), 0) ) + Number( _vm.list2 .map((el) => el.pricesId.price) .reduce((a, b) => Number(a) + Number(b), 0) ) + (_vm.showM ? Number(_vm.body.pricesId.price) : 0))+"€ "),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_vm._v(" Tip:"),_c('span',[_vm._v("........................")]),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')]):_vm._e()]),_c('tr',{staticClass:"total"},[_c('td',[(_vm.body.paymentMethod == 'online')?_c('span',[_vm._v("Online")]):_vm._e(),(_vm.body.paymentMethod == 'cash')?_c('span',[_vm._v("Vor Ort")]):_vm._e(),(_vm.body.paymentMethod == 'hotel')?_c('span',[_vm._v("Zimmer Nr : "+_vm._s(_vm.body.hotel))]):_vm._e(),(_vm.body.paymentMethod == 'Gift')?_c('span',[_vm._v("Gutschein Nr :"+_vm._s(_vm.body.giftsId ? _vm.body.giftsId.id : ""))]):_vm._e(),(_vm.body.paymentMethod == 'Package')?_c('span',[_vm._v("10er Block Nr :"+_vm._s(_vm.body.packagesUsersId ? _vm.body.packagesUsersId.id : ""))]):_vm._e()]),_vm._m(5)]),_vm._m(6),_vm._m(7)],2)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"top"},[_c('td',{attrs:{"colspan":"2"}},[_c('table',[_c('tr',[_c('td',{staticClass:"title"},[_c('img',{staticStyle:{"width":"100%","max-width":"230px"},attrs:{"src":require("../../../assets/logo2.png")}})]),_c('td')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_vm._v(" Farah Awni Selman"),_c('br'),_vm._v(" Messestraße 1, 6850 Dornbirn"),_c('br'),_vm._v(" T +43 (0) 5572 3888 562"),_c('br'),_vm._v(" H +43 (0) 660 10 2 10 10 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"heading"},[_c('td',[_vm._v("Artikel")]),_c('td',[_vm._v("Preis")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br'),_c('br'),_c('br'),_c('br')]),_c('td',[_c('br'),_c('br'),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"item"},[_c('td',{staticStyle:{"border-bottom":"2px solid #000"}}),_c('td',{staticStyle:{"border-bottom":"2px solid #000"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"border-top":"none"}},[_vm._v(" Unterschrift:"),_c('span',[_vm._v("................................................")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')]),_c('td',[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v(" Bankverbindung: "),_c('br'),_vm._v(" Farah Awni Selman "),_c('br'),_vm._v(" IBAN: AT17 3742 2000 0014 0574 "),_c('br'),_vm._v(" BIC:RVVGAT2B422 ")]),_c('td',[_vm._v(" Email: info@kleopatramassage-beauty.com "),_c('br'),_vm._v(" Webseite: https://kleopatramassage-beauty.com ")])])
}]

export { render, staticRenderFns }